import { ref } from "vue";
import { useBapi } from "@/bapi-client";
import { ReportingGroup } from "@/bapi-client/types/autocomplete";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";

export function useReportingGroups() {
  const reportingGroups = ref<ReportingGroup[]>([]);

  async function getReportingGroups(customerId: string, searchTerm?: string): Promise<ReportingGroup[] | undefined> {
    const result = await useBapi(BAPI_COMMANDS.GET_REPORTING_GROUPS, customerId, searchTerm);
    if (!result.success) {
      console.error("Failed to fetch reporting groups: ", result.error);
      return undefined;
    }
    reportingGroups.value = result.data.data;
    return result.data.data;
  }

  return { reportingGroups, getReportingGroups };
}
