import { RouteRecordRaw } from "vue-router";
import PatternAndTenderForm from "@/components/waybilling/PatternAndTenderForm.vue";
import WaybillsList from "@/components/waybilling/WaybillsList.vue";
import PatternsList from "@/components/waybilling/PatternsList.vue";
import WaybillingPage from "@/pages/waybilling/WaybillingPage.vue";

const routes: RouteRecordRaw = {
  name: "waybillingPage",
  path: "/waybilling",
  component: WaybillingPage,
  meta: {
    authenticated: true,
    flag: "hasWaybillModule",
    breadcrumbs: [
      { label: "Waybill Management" },
      { label: "Booking Requests", to: "waybillsList", on: ["waybillsList"] },
      { label: "Patterns", to: "patternsList", on: ["patternsList"] },
      { label: "Pattern Management", to: "createPattern", on: ["createPattern"] },
      { label: "Create Tender", to: "createTender", on: ["createTender"] },
      { label: "Tender Request", to: "replayTenderRequest", on: ["replayTenderRequest"] },
      { label: "Tender Request", to: "viewTenderRequest", on: ["viewTenderRequest"] },
    ],
  },
  children: [
    {
      name: "waybillsList",
      path: ":companyId/waybills",
      component: WaybillsList,
      props: true,
      meta: {
        title: "Telegraph - Waybill Management",
      },
    },
    {
      name: "patternsList",
      path: ":companyId/patterns",
      component: PatternsList,
      props: true,
      meta: {
        title: "Telegraph - Pattern Management",
      },
    },
    {
      name: "createPattern",
      path: ":companyId/pattern/create",
      component: PatternAndTenderForm,
      props: true,
      meta: {
        title: "Telegraph - Create Pattern",
      },
    },
    {
      name: "editPattern",
      path: ":companyId/pattern/:patternId/edit",
      component: PatternAndTenderForm,
      props: true,
      meta: {
        title: "Telegraph - Edit Pattern",
      },
    },
    {
      name: "createTender",
      path: ":companyId/pattern/:patternId/create-tender",
      component: PatternAndTenderForm,
      props: true,
      meta: {
        title: "Telegraph - Create Tender",
      },
    },
    {
      name: "viewTenderRequest",
      path: ":companyId/tender-request/:requestId/view",
      component: PatternAndTenderForm,
      props: true,
      meta: {
        title: "Telegraph - View Tender Request",
      },
    },
    {
      name: "replayTenderRequest",
      path: ":companyId/tender-request/:requestId/replay",
      component: PatternAndTenderForm,
      props: true,
      meta: {
        title: "Telegraph - Replay Tender Request",
      },
    },
  ],
};

export default routes;
