<script setup lang="ts">
import { useFloating, flip, autoUpdate, offset } from "@floating-ui/vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { ref } from "vue";
import DotMenuButton from "@/components/common/buttons/DotMenuButton.vue";
import FileTag from "@/components/common/tags/FileTag.vue";
import { ProfilePicture as ProfilePictureComponent } from "@/components/common";
import { useUserStore } from "@/stores/user";
import { ProfilePicture } from "@/bapi-client/types/users";
import { FileAttachment, Thumbnail } from "@/bapi-client/types/common/attachment";

interface Props {
  userName: string;
  userInitials: string;
  noteId: string;
  userId: string;
  note: string | null;
  showAttachments?: boolean;
  withMenu?: boolean;
  fileCount: number;
  timestamp: string;
  subtitle?: string;
  isInternal?: boolean;
  isClickable: boolean;
  thumbnails?: Thumbnail[];
  files?: FileAttachment[];
  profilePicture: ProfilePicture | null;
}

const confirmDelete = ref(false);

const userStore = useUserStore();

const props = withDefaults(defineProps<Props>(), {
  showAttachments: true,
  withMenu: false,
  subtitle: "",
  thumbnails: () => [],
  files: () => [],
  userId: "",
});

const emit = defineEmits<{
  (event: "download-file", value: string): void;
  (event: "preview-file", value: string): void;
  (event: "delete-note", value: string): void;
}>();

const reference = ref(null);
const floating = ref(null);
const { floatingStyles } = useFloating(reference, floating, {
  strategy: "absolute",
  placement: "bottom-end",
  middleware: [offset({ crossAxis: 16 }), flip()],
  whileElementsMounted: autoUpdate,
});

async function deleteNote(cb: () => unknown) {
  confirmDelete.value = false;
  emit("delete-note", props.noteId);
  cb();
}
</script>

<template>
  <li class="w-full py-3 first:pt-0" :class="{ 'hover:cursor-pointer': isClickable }">
    <div class="flex items-start gap-3">
      <ProfilePictureComponent
        :url="props.profilePicture?.thumbnail_url ?? ''"
        :initials="props.userInitials"
        size="3"
        :anon="props.userId.length < 1"
      />
      <div class="flex w-full flex-col">
        <p class="text-sm font-bold text-gray-900">{{ isInternal ? userName + " - Internal Only" : userName }}</p>
        <p class="inline-block text-sm">
          <span v-if="subtitle" data-testid="note-subtitle" class="mr-1 font-semibold text-gray-600">
            {{ subtitle }}
          </span>
          <span class="font-light text-gray-600">
            {{ $date("MM/DD HH:mm", timestamp) }}
          </span>
        </p>
        <p v-if="note" class="mt-3 text-sm text-gray-700" data-test="note-text">
          {{ note }}
        </p>
      </div>
      <Menu v-if="withMenu && userId === userStore.user?.userId" v-slot="{ close }">
        <MenuButton ref="reference" @click.stop>
          <DotMenuButton :is-large="false" />
        </MenuButton>
        <div ref="floating" class="absolute z-50" :style="floatingStyles">
          <MenuItems class="flex flex-col rounded-md border bg-white shadow-sm">
            <MenuItem
              as="a"
              class="rounded-t-md px-4 py-2 text-left text-red-400 only-of-type:rounded-md hover:bg-red-400 hover:text-white"
              @click.stop.prevent="confirmDelete = true"
            >
              <span><i class="fa-solid fa-trash-can mr-2" /></span>
              Delete Note
            </MenuItem>
            <MenuItem
              v-if="confirmDelete"
              as="a"
              class="rounded-b-md px-4 py-2 text-left text-red-400 hover:bg-red-600 hover:text-white"
              @click.stop.prevent="deleteNote(close)"
            >
              Confirm Delete
            </MenuItem>
          </MenuItems>
        </div>
      </Menu>
    </div>
    <template v-if="showAttachments === true">
      <div v-if="thumbnails.length" class="ml-12 flex flex-row flex-wrap items-start gap-2 pl-2 pt-4">
        <template v-for="thumbnail in thumbnails" :key="thumbnail.id">
          <span
            v-if="thumbnail.loading === true"
            class="flex h-24 w-48 flex-col items-center justify-center rounded-md border text-center align-middle text-blue"
          >
            <i class="fa-solid fa-circle-notch fa-spin fa-2x"></i>
          </span>
          <img
            v-if="thumbnail.loading === false && thumbnail.src"
            :src="thumbnail.src"
            width="200"
            class="rounded-md border transition hover:shadow-md hover:ring hover:ring-blue"
            @click="$emit('preview-file', thumbnail.id)"
          />
        </template>
      </div>
      <div v-if="files.length" class="ml-10 flex flex-row flex-wrap items-start gap-2 pl-2 pt-4">
        <template v-for="file in files" :key="file.id">
          <FileTag
            v-if="file.mimeType.startsWith('video/') || file.mimeType.startsWith('image/')"
            :file="file"
            @click="$emit('preview-file', file.id)"
          />
          <FileTag v-else :file="file" @click="$emit('download-file', file.id)" />
        </template>
      </div>
    </template>
    <div v-else>
      <p v-if="fileCount > 0" class="mt-1 pl-12 text-sm text-gray-600" data-test="note-text">
        <span class="font-medium text-gray-600">
          <i class="fa-light fa-paperclip"></i>
        </span>
        {{ fileCount }}
      </p>
    </div>
  </li>
</template>
