import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { AssetHistoryResponse } from "@/bapi-client/types/assets/assetHistory";
import { fetchHandler } from "@/bapi-client/fetch";
import type { Result } from "@/bapi-client/types";
import {
  BAPIGetVisibilityAssetsResponse,
  BAPIParamsGetVisibilityAssets,
  VisibilityAssetFilters,
} from "@/bapi-client/types/assets/visibilityAsset";
import dayjs from "dayjs";
import FileTypes from "@/models/fileTypes";

export async function getAssetHistory(
  customerId: string,
  assetId: string,
  params: {
    destination?: string;
    load_empty_status?: string;
    max_date_str?: string;
    min_date_str?: string;
    origin?: string;
    page?: number;
    page_size?: number;
  },
): Promise<Result<AssetHistoryResponse>> {
  const path = `/assets/${customerId}/${assetId}`;

  const fetchResult = await fetchHandler(BAPI_COMMANDS.GET_ASSET_HISTORY, "POST", path, {
    body: params,
  });

  if (!fetchResult.success) {
    return fetchResult;
  }

  const jsonResult = await fetchResult.data.json();
  if (!jsonResult.success) return jsonResult;

  return {
    success: true,
    data: jsonResult.data,
  };
}

export async function getVisiblityAssets(
  customerId: string,
  filters: BAPIParamsGetVisibilityAssets,
): Promise<Result<BAPIGetVisibilityAssetsResponse>> {
  const path = `/waybills/${customerId}/dashboard`;

  const result = await fetchHandler(BAPI_COMMANDS.GET_VISIBILITY_ASSETS, "POST", path, {
    body: filters,
  });
  if (!result.success) {
    return result;
  }
  const jsonResult = await result.data.json();
  if (!jsonResult.success) {
    return jsonResult;
  }
  return {
    success: jsonResult.success,
    data: jsonResult.data,
  };
}
export async function getVisibilityAssetsDownloadable(
  customerId: string,
  fileType: FileTypes,
  filters: VisibilityAssetFilters,
) {
  const path = fileType === "csv" ? `/waybills/${customerId}/dashboard/csv` : `/waybills/${customerId}/dashboard/xlsx`;
  const result = await fetchHandler(BAPI_COMMANDS.GET_VISIBILITY_ASSETS_DOWNLOADABLE, "POST", path, {
    body: filters,
  });
  if (!result.success) {
    return result;
  }
  const blob = await result.data.blob();
  if (!blob.success) {
    return blob;
  }
  return {
    success: blob.success,
    data: {
      blob: blob.data,
      download:
        result.data.raw().headers.get("downloadable_as") ??
        `visibility_assets_${dayjs().format("MM-DD-YYYY")}.${fileType}`,
    },
  };
}
