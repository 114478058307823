import { fetchHandler } from "@/bapi-client/fetch";
import type { Result } from "@/bapi-client/types";
import { AutocompleteAsset, BAPIAutocompleteParams } from "@/bapi-client/types/autocomplete";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { toAlphanumeric } from "@/utils/text";

export async function autocompleteAssets(
  customerId: string,
  params: { searchTerm: string; page?: number; pageSize?: number },
): Promise<Result<AutocompleteAsset[]>> {
  const path = `/autocomplete/${customerId}/equipment_id`;
  const body: BAPIAutocompleteParams = {
    search_term: toAlphanumeric(params.searchTerm),
    page: params.page,
    page_limit: params.pageSize,
  };

  const fetchResult = await fetchHandler(BAPI_COMMANDS.AUTOCOMPLETE_ASSETS, "POST", path, {
    body,
  });

  if (!fetchResult.success) {
    return fetchResult;
  }

  const jsonResult = await fetchResult.data.json();
  if (!jsonResult.success) return jsonResult;

  return {
    success: true,
    data: jsonResult.data.data,
  };
}
