import * as telegraph from "@telegraphio/papi-client";

export class AuthMiddleware {
  constructor(public token: string) {}

  async pre(context: telegraph.RequestContext): Promise<telegraph.FetchParams | void> {
    const headers: HeadersInit = {
      ...(context.init.headers as Record<string, string>),
      "x-tg-booking-auth": this.token,
    };

    if (import.meta.env.VITE_LOCAL_AND_STAGING_APP) {
      headers["x-tg-test-request"] = "true";
    }

    context.init.headers = headers;

    return {
      url: context.url,
      init: context.init,
    };
  }
}

export function usePapiClient() {
  const config = new telegraph.Configuration({
    ...telegraph.DefaultConfig,
    basePath: import.meta.env.VITE_PAPI_CLIENT_API_URL,
  });

  const api = new telegraph.BookingApi(config);

  const token = localStorage.getItem("authToken") || "";
  const middleW = new AuthMiddleware(token);

  const papiApi = api.withMiddleware(middleW);

  return {
    papiApi,
  };
}
