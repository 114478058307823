<script setup lang="ts">
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
import useTrackerStore from "@/stores/trackers";
import { TgButton } from "@/components/common";
import { PageHeader } from "@/components/layout";

const route = useRoute();
const trackers = useTrackerStore();

const pageTitle = computed(() => {
  switch (route.name) {
    case "waybillsList":
      return "Booking Requests";
    case "patternsList":
      return "Patterns";
    case "createPattern":
      return "Create Pattern";
    case "editPattern":
      return "Edit Pattern";
    case "createTender":
      return "Create Tender";
    case "viewTenderRequest":
      return "View Tender Request";
    case "replayTenderRequest":
      return "Replay Tender Request";
    default:
      return "";
  }
});

const isAdvancedMode = ref(false);

const isListPage = computed(() => route.name === "waybillsList" || route.name === "patternsList");
const isPatternEditable = computed(() => route.name === "editPattern" || route.name === "createPattern");
</script>

<template>
  <div class="grid h-screen grid-rows-[min-content_1fr] gap-6 p-4">
    <PageHeader>
      <template #title>
        <span v-if="route.name === 'patternsList'"><i class="fa-solid fa-layer-group"></i></span>
        <span v-if="route.name === 'waybillsList'"><i class="fa-light fa-file-invoice"></i></span>
        {{ pageTitle }}
      </template>
      <template v-if="isListPage" #actions>
        <TgButton
          v-if="route.name === 'patternsList'"
          :to="{ name: 'createPattern' }"
          @click="trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.WAYBILLING_PATTERN_LIST_CREATE_PATTERN, {})"
        >
          Create Pattern
        </TgButton>
      </template>
      <template v-else-if="isPatternEditable" #actions>
        <FormKit v-model="isAdvancedMode" type="toggle" name="api-toggle" label="Advanced Mode" />
      </template>
    </PageHeader>
    <router-view :is-advanced-mode="isAdvancedMode" />
  </div>
</template>
