// FormkitAdapter transforms the data from the EDI Manager into the formkit schem
import { SchemaNode } from "@telegraphio/tedi-view-builder/node";
import { DisplayAdapter, Render } from "@telegraphio/tedi-view-builder/renderer";
import { formatEDILabel } from "@/utils/edi";

export type FormKitNode = {
  $formkit?: string;
  $el?: string;
  type?: string;
  name?: string;
  label?: string;
  children?: FormKitNode[] | string;
  value?: unknown;
  ediPath?: string;
  [key: string]: unknown; // Additional FormKit properties
};

const sectionsSchema = {
  label: {
    children: [
      {
        $el: "div",
        attrs: {
          class: "flex items-center gap-2",
        },
        children: [
          {
            if: "$state.required",
            $el: "span",
            children: "✱",
            attrs: {
              class: "group-data-[invalid]:text-red-500",
            },
          },
          {
            $el: "label",
            children: "$label",
            attrs: {
              class: "font-semibold group-data-[invalid]:text-red-500",
            },
          },
          {
            $cmp: "EditEdiFormField",
            props: {
              fieldId: "$id",
              currentLabel: "$label",
            },
          },
        ],
      },
    ],
  },
};

/*
 * Base types
 */

// Element
// This *does not* need to recursively call Render, as it's a leaf node
export const BaseElementAdapter: DisplayAdapter<FormKitNode> = {
  label: "BaseElementAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.type === "element";
  },
  render: (currentSchema: SchemaNode, transformers: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    if (!currentSchema.properties) {
      // try using a string
      return ElementStringAdapter.render(currentSchema, transformers);
    }
    // this is a value, so switch on the type
    switch (currentSchema.properties.type) {
      case "string":
        return ElementStringAdapter.render(currentSchema, transformers);
      case "number":
        return ElementNumberAdapter.render(currentSchema, transformers);
      case "integer":
        return ElementIntegerAdapter.render(currentSchema, transformers);
      default:
        // just try using a string
        return ElementStringAdapter.render(currentSchema, transformers);
    }
  },
};

// Object
// This needs to recursively call Render for each child
export const BaseObjectAdapter: DisplayAdapter<FormKitNode> = {
  label: "BaseObjectAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.type === "object";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);

    // add this object to the result
    const objectSchema = new ObjectFormKitSchema(name, label, currentSchema.parent?.label === "heading");
    result.push(...objectSchema.render());

    // then since this is an object, we need to continue down the tree
    for (const child of currentSchema.children) {
      const newResult = Render(child, adapters);
      if (newResult) {
        result.push(...newResult);
      }
    }

    // INFO: we could, for example, then also add another formkit object at the end, after we've gone through all the children
    // result.push(...objectSchema.finish());

    return result;
  },
};

class ObjectFormKitSchema {
  constructor(
    public name: string,
    public title: string,
    public isRootSegmentHeader: boolean,
  ) {}

  public render(): FormKitNode[] {
    const result: FormKitNode[] = [new HeaderFormKitSchema(this.name, this.title, this.isRootSegmentHeader).render()];

    return result;
  }

  public finish(): FormKitNode[] {
    return [];
  }
}

export const BaseArrayAdapter: DisplayAdapter<FormKitNode> = {
  label: "BaseArrayAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.type === "array";
  },
  render: (currentSchema: SchemaNode, transformers: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);

    // add this object to the result
    const objectSchema = new ArrayFormKitSchema(
      name,
      label,
      (currentSchema.properties?.tgAllowReorder as boolean) || false,
      currentSchema.value,
      currentSchema.ediPath?.split("."),
    ).render(); // TODO: Audit the schema being passed in here

    const children: FormKitNode[] = [];
    // then since this is an object, we need to continue down the tree
    for (const child of currentSchema.children) {
      const childResult = Render(child, transformers);
      if (childResult) {
        children.push(...childResult);
      }
    }

    objectSchema.children = children;

    const result: FormKitNode[] = [];

    if (!currentSchema.properties?.tgNoHeader) {
      result.push(new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render());
    }

    result.push(objectSchema);

    return result;
  },
};

function baseRepeater(isFlex: boolean, allowReorder = false): FormKitNode {
  return {
    $formkit: "repeater",
    "add-button": false,
    "insert-control": true,
    "move-down-icon": "select",
    "move-up-icon": "up",
    "remove-icon": "minusSquareSolid",
    "add-icon": "plusSquareSolid",
    "up-control": false,
    "down-control": false,
    classes: {
      outer: "bg-transparent !border-none",
      inner: "border-none",
      item: "flex gap-1.5 border-none",
      content: `${!allowReorder ? "p-6 " : "!pb-16 !px-6 "} border bg-white shadow${isFlex ? " !flex-row flex-wrap gap-y-3 gap-x-8" : ""}`,
      controls: "bg-white border shadow",
      legend: "text-xl font-semibold",
      addIcon: "text-blue hover:text-blue-300 text-xl",
      removeIcon: "text-blue hover:text-blue-300 text-xl",
      removeControl: "disabled:opacity-25",
    },
  };
}

class ArrayFormKitSchema {
  constructor(
    public name: string,
    public title: string,
    public allowReorder: boolean,
    public value?: any,
    public ediPath?: string[],
  ) {}

  public render(): FormKitNode {
    const result: FormKitNode = {
      ...baseRepeater(true, this.allowReorder),
      "up-control": this.allowReorder,
      "down-control": this.allowReorder,
    };

    if (this.ediPath && this.ediPath.length > 0) {
      result.ediPath = this.ediPath.join(".");
      result.name = this.ediPath.pop();
    } else {
      result.name = this.name;
    }

    if (this.value) {
      result.value = this.value;
    }

    return result;
  }
}

class HeaderFormKitSchema {
  constructor(
    public name: string,
    public title: string,
    public isRootSegmentHeader: boolean,
  ) {}

  public render(): FormKitNode {
    const size = this.isRootSegmentHeader ? "text-xl" : "text-lg";

    return {
      $el: "h1",
      children: this.title,
      name: this.name,
      attrs: {
        class: `${size} font-semibold mb-2`,
      },
    };
  }
}

// String
// This is a leaf node, so it doesn't need to recursively call Render
export const ElementStringAdapter: DisplayAdapter<FormKitNode> = {
  label: "ElementStringAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.properties?.type === "string";
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const result: FormKitNode[] = [];
    const name = currentSchema.label ? currentSchema.label : currentSchema.id;
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);
    const width = currentSchema.properties?.tgWidth ? (currentSchema.properties.tgWidth as string) : "";
    let minLength = 0;
    let maxLength = 255; // we just default to this, it could be anything else
    if (currentSchema.properties) {
      minLength = currentSchema.properties.minLength ? (currentSchema.properties.minLength as number) : minLength;
      maxLength = currentSchema.properties.maxLength ? (currentSchema.properties.maxLength as number) : maxLength; // we just default to this, it could be anything else
    }

    const node = new StringFormKitSchema(
      name,
      label,
      minLength,
      maxLength,
      currentSchema.value,
      currentSchema.ediPath?.split("."),
      width,
    );

    // we know this has no children, so don't need to continue any further. Append the result, and return it
    result.push(...node.render());
    return result;
  },
};

export class StringFormKitSchema {
  constructor(
    public name: string,
    public label: string,
    public minLength: number,
    public maxLength: number,
    public value?: any,
    public ediPath?: string[],
    public width?: string,
  ) {}

  public render(): FormKitNode[] {
    const result: FormKitNode = {
      $formkit: "text",
      label: this.label,
      validation: `length:${this.minLength},${this.maxLength}`,
      classes: {
        outer: `grow-0${this.width ? " " + this.width : ""}`,
        inner: "group-data-[invalid]:border group-data-[invalid]:border-red-500",
      },
      sectionsSchema,
    };
    if (this.value) {
      result.value = this.value;
    }

    if (this.ediPath && this.ediPath.length > 0) {
      result.name = this.ediPath[0];
      result.ediPath = this.ediPath.join(".");
    } else {
      result.name = this.name;
    }

    return [result];
  }
}

// Number
// This is a leaf node, so it doesn't need to recursively call Render
export const ElementNumberAdapter: DisplayAdapter<FormKitNode> = {
  label: "ElementNumberAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.properties?.type === "number";
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const result: FormKitNode[] = [];
    const name = currentSchema.label ? currentSchema.label : "";
    const label = formatEDILabel(currentSchema.label);

    const splitEDIPath = currentSchema.ediPath?.split(".");
    const node = new NumberFormKitSchema(
      name,
      label,
      currentSchema.properties?.minimum as number,
      currentSchema.properties?.maximum as number,
      currentSchema.properties?.["x12-max-length"] as number,
      currentSchema.value as number,
      splitEDIPath,
    );
    // we know this has no children, so don't need to continue any further. Append the result, and return it
    result.push(...node.render());
    return result;
  },
};

export class NumberFormKitSchema {
  constructor(
    public name: string,
    public label: string,
    public min: number,
    public max: number,
    public maxLength: number,
    public value?: number,
    public ediPath?: string[],
  ) {}

  public render(): FormKitNode[] {
    const leaf: FormKitNode = {
      $formkit: "number",
      label: this.label,
      name: this.name,
      min: this.min,
      max: this.max,
      value: this.value,
      classes: {
        outer: "grow-0",
        inner: "group-data-[invalid]:border group-data-[invalid]:border-red-500",
      },
      sectionsSchema,
    };

    if (this.ediPath && this.ediPath.length > 0) {
      leaf.name = this.ediPath[0];
      leaf.ediPath = this.ediPath.join(".");
    }

    return [leaf];
  }
}

// Integer
// This is a leaf node, so it doesn't need to recursively call Render
export const ElementIntegerAdapter: DisplayAdapter<FormKitNode> = {
  label: "ElementIntegerAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.properties?.type === "integer";
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const result: FormKitNode[] = [];
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);
    let min = 0;
    let max = 9999999; // we just default to this, it could be anything else
    if (currentSchema.properties) {
      min = currentSchema.properties.minimum ? (currentSchema.properties.minimum as number) : min;
      max = currentSchema.properties.maximum ? (currentSchema.properties.maximum as number) : max; // we just default to this, it could be anything else
    }
    const splitEDIPath = currentSchema.ediPath?.split(".");
    const node = new IntegerFormKitSchema(
      name,
      label,
      min,
      max,
      currentSchema.properties?.["x12-max-length"] as number,
      currentSchema.value as number,
      splitEDIPath,
    );
    // we know this has no children, so don't need to continue any further. Append the result, and return it
    result.push(...node.render());
    return result;
  },
};

export class IntegerFormKitSchema {
  constructor(
    public name: string,
    public label: string,
    public min: number,
    public max: number,
    public maxLength: number,
    public value?: number,
    public ediPath?: string[],
  ) {}

  public render(): FormKitNode[] {
    const out: FormKitNode = {
      $formkit: "number",
      label: this.label,
      name: this.name,
      min: this.min,
      max: this.max,
      value: this.value,
      classes: {
        outer: "grow-0",
        inner: "group-data-[invalid]:border group-data-[invalid]:border-red-500",
      },
      sectionsSchema,
    };

    if (this.ediPath && this.ediPath.length > 0) {
      out.name = this.ediPath[0];
      out.ediPath = this.ediPath.join(".");
    }

    return [out];
  }
}

/*
 * Custom types
 */

// Dropdown
// This is a leaf node, so it doesn't need to recursively call Render

const dropdownWidths: Record<string, string> = {
  "general_shipment_information_BX.transportation_method_type_code_02": "w-[226px]",
  "general_shipment_information_BX.shipment_method_of_payment_code_03": "w-[406px]",
  "general_shipment_information_BX.standard_carrier_alpha_code_05": "w-[140px]",
  "general_shipment_information_BX.shipment_qualifier_07": "w-[528px]",
  "rail_shipment_information_BNX.shipment_weight_code_01": "w-[418px]",
  "rail_shipment_information_BNX.billing_code_03": "w-[450px]",
  "release_M3.release_code_01": "w-[388px]",
  "release_M3.time_code_04": "w-[254px]",
  "extended_reference_information_N9.reference_identification_qualifier_01": "w-[316px]",
  "transaction_set_line_number_LX_loop.description_marks_and_numbers_L5.commodity_code_qualifier_04": "w-[404px]",
  "transaction_set_line_number_LX_loop.description_marks_and_numbers_L5.packaging_code_05": "w-[352px]",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.line_item_quantity_and_weight_L0.weight_qualifier_05":
    "w-[214px]",
  "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.price_authority_identification_PI_loop.price_authority_identification_PI.reference_identification_qualifier_01":
    "w-[212px]",
  "equipment_details_N7_loop.equipment_details_N7.weight_qualifier_04": "w-[214px]",
  "equipment_details_N7_loop.equipment_details_N7.equipment_description_code_11": "w-[288px]",
  "route_information_R2.routing_sequence_code_02": "w-[356px]",
  "party_identification_N1_loop.party_identification_N1.entity_identifier_code_01": "w-[286px]",
  "party_identification_N1_loop.geographic_location_N4.state_or_province_code_02": "w-[236px]",
  "party_identification_N1_loop.geographic_location_N4.country_code_04": "w-[140px]",
  "protective_service_instructions_PS.protective_service_code_02": "w-[346px]",
  "empty_car_disposition_pended_destination_consignee_E1_loop.price_authority_identification_PI.reference_identification_qualifier_01":
    "w-[212px]",
};

export const DropdownAdapter: DisplayAdapter<FormKitNode> = {
  label: "DropdownAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.properties?.tgType === "tg-dropdown";
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const result: FormKitNode[] = [];
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);
    const width = dropdownWidths[currentSchema.ediPath ?? ""] ?? "";

    const node = new DropdownFormKitSchema(
      name,
      label,
      currentSchema.properties?.tgOptions as string[],
      currentSchema.value,
      currentSchema.ediPath?.split("."),
      width,
    );
    // we know this has no children, so don't need to continue any further. Append the result, and return it
    result.push(...node.render());
    return result;
  },
};

export class DropdownFormKitSchema {
  constructor(
    public name: string,
    public label: string,
    public options: string[],
    public value?: any,
    public ediPath?: string[],
    public width?: string,
  ) {}

  public render(): FormKitNode[] {
    const result: FormKitNode = {
      $formkit: "dropdown",
      label: this.label,
      classes: {
        outer: `${this.width} grow-0`,
        inner: "bg-white group-data-[invalid]:border group-data-[invalid]:border-red-500",
      },
      options: this.options,
      sectionsSchema,
    };

    if (this.value) {
      result.value = this.value;
    }

    if (this.ediPath && this.ediPath.length > 0) {
      result.name = this.ediPath[0];
      result.ediPath = this.ediPath.join(".");
    } else {
      result.name = this.name;
    }

    return [result];
  }
}

// Date
export const DateAdapter: DisplayAdapter<FormKitNode> = {
  label: "DateAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.properties?.type === "string" && node.properties?.format === "date";
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);
    const node = new DateFormKitSchema(
      name,
      label,
      currentSchema.value as string | undefined,
      currentSchema.ediPath?.split("."),
    );

    return [...node.render()];
  },
};

export class DateFormKitSchema {
  constructor(
    public name: string,
    public label: string,
    public value?: string,
    public ediPath?: string[],
  ) {}

  public render(): FormKitNode[] {
    const result: FormKitNode = {
      $formkit: "date",
      label: this.label,
      classes: {
        outer: "grow-0",
        inner: "group-data-[invalid]:border group-data-[invalid]:border-red-500",
      },
      sectionsSchema,
    };

    if (this.value) {
      result.value = this.value;
    }

    if (this.ediPath && this.ediPath.length > 0) {
      result.name = this.ediPath[0];
      result.ediPath = this.ediPath.join(".");
    } else {
      result.name = this.name;
    }

    return [result];
  }
}

// Time
export const TimeAdapter: DisplayAdapter<FormKitNode> = {
  label: "TimeAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return !!node.ediPath?.endsWith("time_03") || !!node.ediPath?.endsWith("time_05");
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);
    const node = new TimeFormKitSchema(
      name,
      label,
      currentSchema.value as string | undefined,
      currentSchema.ediPath?.split("."),
    );

    return [...node.render()];
  },
};

export class TimeFormKitSchema {
  constructor(
    public name: string,
    public label: string,
    public value?: string,
    public ediPath?: string[],
  ) {}

  public render(): FormKitNode[] {
    const result: FormKitNode = {
      $formkit: "time",
      label: this.label,
      classes: {
        outer: "grow-0",
        inner: "group-data-[invalid]:border group-data-[invalid]:border-red-500",
      },
      sectionsSchema,
    };

    if (this.value) {
      result.value = this.value;
    }

    if (this.ediPath && this.ediPath.length > 0) {
      result.name = this.ediPath[0];
      result.ediPath = this.ediPath.join(".");
    } else {
      result.name = this.name;
    }

    return [result];
  }
}

// Checkbox
export const CheckboxAdapter: DisplayAdapter<FormKitNode> = {
  label: "CheckboxAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.properties?.tgType === "tg-checkbox";
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);
    const node = new CheckboxFormKitSchema(
      name,
      label,
      currentSchema.properties?.tgOnValue as string,
      currentSchema.properties?.tgOffValue as string,
      currentSchema.value ?? currentSchema.properties?.tgOffValue,
      currentSchema.ediPath?.split("."),
    );

    return [...node.render()];
  },
};

export class CheckboxFormKitSchema {
  constructor(
    public name: string,
    public label: string,
    public onValue: string,
    public offValue: string,
    public value?: any,
    public ediPath?: string[],
  ) {}

  public render(): FormKitNode[] {
    const result: FormKitNode = {
      $formkit: "checkbox",
      label: this.label,
      "on-value": this.onValue,
      "off-value": this.offValue,
      classes: {
        outer: "grow-0",
        decorator: "bg-white",
      },
    };

    if (this.value) {
      result.value = this.value;
    }

    if (this.ediPath && this.ediPath.length > 0) {
      result.name = this.ediPath[0];
      result.ediPath = this.ediPath.join(".");
    } else {
      result.name = this.name;
    }

    return [result];
  }
}

// Temperature Unit Selector
export const TemperatureUnitAdapter: DisplayAdapter<FormKitNode> = {
  label: "TemperatureUnitAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.properties?.tgType === "tg-temperature-unit";
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);

    const radio: FormKitNode = {
      $formkit: "togglebuttons",
      name: currentSchema.ediPath ? currentSchema.ediPath.split(".")[0] : name,
      label,
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
      options: [
        { value: "FA", label: "°F" },
        { value: "CE", label: "°C" },
      ],
      classes: {
        outer: "grow-0",
        inputInner: "px-1.5",
      },
      sectionsSchema,
    };

    return [radio];
  },
};

export const CarrierLookupAdapter: DisplayAdapter<FormKitNode> = {
  label: "CarrierLookupAdapter",
  canHandle: (node: SchemaNode): boolean => {
    const fields = ["standard_carrier_alpha_code_01", "issuing_carrier_identifier_06"];
    const ediPath = node.ediPath?.split(".").pop() || "";

    return fields.includes(ediPath);
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);

    return [
      {
        $formkit: "autocomplete",
        value: currentSchema.value,
        ediPath: currentSchema.ediPath,
        label,
        name,
        placeholder: "Search SCACs",
        options: "$searchScacs",
        debounce: 500,
        classes: {
          outer: "grow-0",
          inner: "bg-white",
        },
        sectionsSchema,
      },
    ];
  },
};

export const JunctionLookupAdapter: DisplayAdapter<FormKitNode> = {
  label: "JunctionLookupAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "route_information_R2.city_name_03";
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);

    return [
      {
        $formkit: "autocomplete",
        value: currentSchema.value,
        ediPath: currentSchema.ediPath,
        label,
        name,
        placeholder: "Search junction cities",
        options: "$searchJunctions",
        debounce: 500,
        classes: {
          outer: "grow-0",
          inner: "bg-white",
        },
        sectionsSchema,
      },
    ];
  },
};

// This builds our equipment details
export const EquipmentAdapter: DisplayAdapter<FormKitNode> = {
  label: "EquipmentAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "equipment_details_N7_loop";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = currentSchema.properties?.tgLabel as string;
    const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();

    result.push(header);

    const repeater: FormKitNode = {
      ...baseRepeater(false),
      name: currentSchema.ediPath,
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
    };

    const children: FormKitNode[] = [];
    for (const child of currentSchema.children) {
      if (child.ediPath === "equipment_details_N7_loop.equipment_details_N7") {
        children.push(...ElementFlexRowAdapter.render(child, adapters));
      } else {
        children.push(...Render(child, adapters));
      }
    }

    repeater.children = children;
    result.push(repeater);

    return result;
  },
};

export const EquipmentReferenceAdapter: DisplayAdapter<FormKitNode> = {
  label: "EquipmentReferenceAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "equipment_details_N7_loop.reference_information_REF_loop";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = formatEDILabel(currentSchema.label);
    const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();

    result.push(header);

    const repeater: FormKitNode = {
      ...baseRepeater(false),
      name: currentSchema.ediPath?.split(".").pop(),
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
    };

    const children: FormKitNode[] = [];
    for (const child of currentSchema.children) {
      if (child.ediPath === "equipment_details_N7_loop.reference_information_REF_loop.reference_information_REF") {
        children.push(...ElementFlexRowAdapter.render(child, adapters));
      } else {
        children.push(...Render(child, adapters));
      }
    }

    repeater.children = children;
    result.push(repeater);

    return result;
  },
};

export const PartyIdentificationAdapter: DisplayAdapter<FormKitNode> = {
  label: "PartyIdentificationAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "party_identification_N1_loop";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = currentSchema.properties?.tgLabel as string;
    const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();

    result.push(header);

    const repeater: FormKitNode = {
      ...baseRepeater(false),
      name: currentSchema.ediPath,
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
    };

    const children: FormKitNode[] = [];

    children.push({
      $formkit: "autocomplete",
      ediPath: currentSchema.ediPath,
      name: "party-lookup",
      label: "Party Lookup",
      placeholder: "Search by party name, address, or CIF code",
      options: "$searchCifs",
      debounce: 1000,
      classes: {
        outer: "grow-0 mb-6",
        inner: "bg-white",
      },
    });

    for (const child of currentSchema.children) {
      if (
        child.ediPath === "party_identification_N1_loop.party_identification_N1" ||
        child.ediPath === "party_identification_N1_loop.geographic_location_N4"
      ) {
        children.push(...ElementFlexRowAdapter.render(child, adapters));
      } else {
        children.push(...Render(child, adapters));
      }
    }

    repeater.children = children;
    result.push(repeater);

    return result;
  },
};

export const LocationLookupAdapter: DisplayAdapter<FormKitNode> = {
  label: "LocationLookupAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "origin_station_F9" || node.ediPath === "destination_station_D9";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);
    const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();

    result.push(header);

    for (const child of currentSchema.children) {
      result.push(...Render(child, adapters));
    }

    let nodeName = "";
    if (currentSchema.ediPath === "origin_station_F9") {
      nodeName = "origin-lookup";
    } else if (currentSchema.ediPath === "destination_station_D9") {
      nodeName = "destination-lookup";
    } else if (currentSchema.ediPath?.startsWith("empty_car_disposition_pended_destination_consignee_E1_loop")) {
      nodeName = "disposition-lookup";
    }

    const autocomplete: FormKitNode = {
      $formkit: "autocomplete",
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
      name: nodeName,
      placeholder: "Search by city or state/province",
      options: "$searchLocations",
      debounce: 500,
      classes: {
        outer: "grow-0 mb-6",
        inner: "bg-white",
      },
    };

    result.push(autocomplete);

    return result;
  },
};

export const LocationFieldAdapter: DisplayAdapter<FormKitNode> = {
  label: "LocationFieldAdapter",
  canHandle: (node: SchemaNode): boolean => {
    if (node.ediPath?.startsWith("party_identification_N1_loop")) {
      return false;
    }

    const fields = [
      "city_name_01",
      "city_name_02",
      "state_or_province_code_02",
      "state_or_province_code_03",
      "country_code_04",
    ];
    const ediPath = node.ediPath?.split(".").pop() || "";

    return fields.includes(ediPath);
  },
  render: (currentSchema: SchemaNode): FormKitNode[] => {
    const name = currentSchema.label ? currentSchema.label : "";

    return [
      {
        $formkit: "hidden",
        name: currentSchema.ediPath ? currentSchema.ediPath.split(".")[0] : name,
        value: currentSchema.value,
        ediPath: currentSchema.ediPath,
      },
    ];
  },
};

export const CommoditiesAdapter: DisplayAdapter<FormKitNode> = {
  label: "CommoditiesAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "transaction_set_line_number_LX_loop";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = currentSchema.properties?.tgLabel as string;
    const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();

    result.push(header);

    const repeater: FormKitNode = {
      ...baseRepeater(false),
      name: currentSchema.ediPath,
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
    };

    const children: FormKitNode[] = [];
    for (const child of currentSchema.children) {
      if (child.ediPath === "transaction_set_line_number_LX_loop.transaction_set_line_number_LX") {
        children.push(...ElementFlexRowAdapter.render(child, adapters));
      } else {
        children.push(...Render(child, adapters));
      }
    }

    repeater.children = children;
    result.push(repeater);

    return result;
  },
};

export const CommodityLookupAdapter: DisplayAdapter<FormKitNode> = {
  label: "CommodityLookupAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "transaction_set_line_number_LX_loop.description_marks_and_numbers_L5";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const repeater: FormKitNode = {
      ...baseRepeater(false),
      name: currentSchema.ediPath?.split(".").pop(),
      label: currentSchema.properties?.tgLabel as string,
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
    };

    const children: FormKitNode[] = [];

    children.push({
      $formkit: "autocomplete",
      name: "commodity-lookup",
      label: "Commodity Lookup",
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
      options: "$searchSttcs",
      placeholder: "Search by STCC or description",
      debounce: 500,
      classes: {
        outer: "grow-0 mb-3",
      },
    });

    const flexRowChildren: FormKitNode[] = [];

    for (const child of currentSchema.children) {
      flexRowChildren.push(...Render(child, adapters));
    }

    children.push({
      $el: "div",
      attrs: {
        class: "flex flex-wrap flex-row gap-x-8 gap-y-3 mb-6 items-end",
      },
      children: flexRowChildren,
    });

    repeater.children = children;
    result.push(repeater);

    return result;
  },
};

export const LineItemAndWeightAdapter: DisplayAdapter<FormKitNode> = {
  label: "LineItemAndWeightAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = currentSchema.properties?.tgLabel as string;
    const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();

    result.push(header);

    const repeater: FormKitNode = {
      ...baseRepeater(false),
      name: currentSchema.ediPath?.split(".").pop(),
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
    };

    const children: FormKitNode[] = [];
    for (const child of currentSchema.children) {
      if (
        child.ediPath ===
        "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.line_item_quantity_and_weight_L0"
      ) {
        children.push(...ElementFlexRowAdapter.render(child, adapters));
      }
      if (
        child.ediPath ===
        "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.price_authority_identification_PI_loop"
      ) {
        children.push(...Render(child, adapters));
      }
    }

    repeater.children = children;
    result.push(repeater);

    return result;
  },
};

export const PriceAuthorityIdentificationAdapter: DisplayAdapter<FormKitNode> = {
  label: "PriceAuthorityIdentificationAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return (
      node.ediPath ===
      "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.price_authority_identification_PI_loop"
    );
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = currentSchema.properties?.tgLabel as string;
    const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();

    result.push(header);

    const repeater: FormKitNode = {
      ...baseRepeater(false),
      name: currentSchema.ediPath?.split(".").pop(),
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
    };

    const children: FormKitNode[] = [];
    for (const child of currentSchema.children) {
      if (
        child.ediPath ===
        "transaction_set_line_number_LX_loop.line_item_quantity_and_weight_L0_loop.price_authority_identification_PI_loop.price_authority_identification_PI"
      ) {
        children.push(...ElementFlexRowAdapter.render(child, adapters));
      }
    }

    repeater.children = children;
    result.push(repeater);

    return result;
  },
};

export const EmptyCarDispositionAdapter: DisplayAdapter<FormKitNode> = {
  label: "EmptyCarDispositionAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.ediPath === "empty_car_disposition_pended_destination_consignee_E1_loop";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = currentSchema.properties?.tgLabel as string;
    const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();

    result.push(header);

    const repeater: FormKitNode = {
      ...baseRepeater(false),
      name: currentSchema.ediPath,
      value: currentSchema.value,
      ediPath: currentSchema.ediPath,
    };

    const children: FormKitNode[] = [];
    for (const child of currentSchema.children) {
      if (
        child.ediPath ===
        "empty_car_disposition_pended_destination_consignee_E1_loop.empty_car_disposition_pended_destination_consignee_E1"
      ) {
        children.push(...ElementFlexRowAdapter.render(child, adapters));
      }
      if (
        child.ediPath ===
        "empty_car_disposition_pended_destination_consignee_E1_loop.empty_car_disposition_pended_destination_city_E4"
      ) {
        children.push(...LocationLookupAdapter.render(child, adapters));
      }
      if (
        child.ediPath === "empty_car_disposition_pended_destination_consignee_E1_loop.price_authority_identification_PI"
      ) {
        children.push(...ElementFlexRowAdapter.render(child, adapters));
      }
    }

    repeater.children = children;
    result.push(repeater);

    return result;
  },
};

// flex wrapped div
export const ElementFlexRowAdapter: DisplayAdapter<FormKitNode> = {
  label: "ElementFlexRowAdapter",
  canHandle: (node: SchemaNode): boolean => {
    return node.type === "object" && node.properties?.tgType === "tg-flex-row";
  },
  render: (currentSchema: SchemaNode, adapters: DisplayAdapter<FormKitNode>[]): FormKitNode[] => {
    const result: FormKitNode[] = [];

    const name = currentSchema.label ? currentSchema.label : "";
    const label = (currentSchema.properties?.tgLabel as string) ?? formatEDILabel(currentSchema.label);

    if (!currentSchema.properties?.tgNoHeader) {
      const header = new HeaderFormKitSchema(name, label, currentSchema.parent?.label === "heading").render();
      result.push(header);
    }

    const section: FormKitNode = {
      $el: "div",
      attrs: {
        class: "flex flex-wrap flex-row gap-x-8 gap-y-3 mb-6 items-end",
      },
    };

    const children: FormKitNode[] = [];

    for (const child of currentSchema.children) {
      const childResult = Render(child, adapters);
      if (childResult) {
        children.push(...childResult);
      }
    }

    section.children = children;
    result.push(section);

    return result;
  },
};

export function wrapLeafInGroup(ediPath: string[], leaf: FormKitNode, isPattern: boolean): FormKitNode {
  let node = leaf;
  const nonVariableInputs = ["group", "checkbox", "hidden"];
  const nonVariablePaths = ["origin_station_F9", "destination_station_D9"];

  if (
    isPattern &&
    leaf.$formkit &&
    !nonVariableInputs.includes(leaf.$formkit) &&
    !nonVariablePaths.includes(leaf.name || "")
  ) {
    node = {
      $el: "div",
      // needs to be flex start so that when the up/down control is added the label starts at the top of the flex container
      attrs: {
        class: "flex items-end",
      },
      children: [
        leaf,
        {
          $formkit: "checkbox",
          bind: "$variableCheckboxAttributes",
          name: `${leaf.name}-checkbox`,
          classes: {
            outer: "$: 'grow-0' + ($showCheckbox === false && ' hidden' || '')",
            decorator: "bg-white mb-0.5 ml-1.5",
          },
        },
      ],
    };
  }

  if (ediPath.length === 0) {
    return node;
  }

  const currentGroup = {
    $formkit: "group",
    name: ediPath.pop(),
    children: [node],
  };
  return wrapLeafInGroup(ediPath, currentGroup, isPattern);
}

// for every, array, and element, that has an edi path, we need pass on that EDI path to FormKitNodes
// what does the solution look like for multiple nested repeaters?
export function ensureGrouping(current: FormKitNode[], isPattern: boolean, trimPrefix: string = ""): FormKitNode[] {
  const result: FormKitNode[] = [];
  for (const node of current) {
    // if the node is an h1 header, just pass it through as is
    if (node.$el === "h1") {
      result.push(node);
      continue;
    }

    if (node.$el === "div") {
      const children: FormKitNode[] = [];
      for (const child of node.children || []) {
        // this shouldn't happen by typescript makes us check
        if (typeof child === "string") {
          continue;
        }
        children.push(...ensureGrouping([child], isPattern, trimPrefix));
      }
      node.children = children;
      result.push(node);
      continue;
    }

    if (node.$formkit === "autocomplete" && node.name?.endsWith("-lookup")) {
      result.push(node);
      continue;
    }

    if (node.$formkit === "repeater") {
      // find the repeater name and trim the prefix to that
      // if this node is a repeater, wrap it in groups down to the repeater
      // ensure grouping for all of the children of the repeater by trimming the prefix to the repeater
      // TODO: this should actually do something
      const toPush = {
        ...node,
        children: ensureGrouping(node.children as FormKitNode[], isPattern, node.ediPath),
      };
      result.push(toPush);
      continue;
    }

    // if this node has an ediPath and is an element, we need to wrap it in a group
    if (node.ediPath) {
      // if there's a prefix to trim, do it
      node.ediPath = trimEDIPathPrefix(node.ediPath, trimPrefix);

      // ensure correct name and grouping
      const pathSplit = node.ediPath.split(".");
      node.name = pathSplit.pop();
      result.push(wrapLeafInGroup(pathSplit, node, isPattern));
    } else {
      result.push(node);
    }
  }

  return result;
}

function trimEDIPathPrefix(path: string, prefix: string): string {
  // return if there's nothing to trim
  if (!prefix) {
    return path;
  }

  // remove the prefix from the path
  if (path.startsWith(prefix)) {
    path = path.slice(prefix.length);
  }

  // remove any leading dots
  if (path.startsWith(".")) {
    path = path.slice(1);
  }

  return path;
}
