<script setup lang="ts">
import { FilterBox, FilterChip } from "@/components/filters";
import { TextButton, TgButton, OutlineButton } from "@/components/common";
import { FilterChipValue } from "@/types";

defineProps<{ name: string; values: Record<string, unknown>; chips: FilterChipValue[] }>();
const emit = defineEmits(["apply", "reset"]);
const open = defineModel("isPanelOpen", { default: false, type: Boolean });

function applyFilters() {
  emit("apply");
  open.value = false;
}
</script>

<template>
  <FormKit
    :id="`${name}-filters`"
    type="form"
    :name="name"
    :actions="false"
    :value="values"
    :classes="{
      form: `grid grid-cols-3 lg:grid-cols-5 xl:grid-cols-8 2xl:grid-cols-[repeat(13,_minmax(0,_1fr))] w-full grid-flow-col transition-all gap-3 my-4`,
    }"
  >
    <div v-show="open" class="col-span-2 grid grid-cols-subgrid gap-3 lg:col-span-4 xl:col-span-7 2xl:col-span-11">
      <slot />
    </div>
    <!-- controls and collapsed panel -->
    <FilterBox
      :class="{
        'col-span-1 rounded-md border border-gray-300 2xl:col-span-2': open,
        'col-span-full grid grid-cols-subgrid gap-3 px-6': !open,
      }"
    >
      <div
        v-show="!open"
        class="col-span-2 flex flex-wrap items-center gap-3 lg:col-span-4 xl:col-span-7 2xl:col-span-11"
      >
        <template v-for="chip in chips" :key="chip?.label">
          <FilterChip v-if="chip?.value" :label="chip.label" :disabled="chip.disabled">
            {{ chip.value }}
          </FilterChip>
        </template>
      </div>
      <div
        class="grid gap-3 text-xs md:text-sm"
        :class="{
          'items-center': open,
          'col-span-1 2xl:col-span-2': !open,
        }"
      >
        <div class="justify-self-end">
          <TextButton
            color="primary"
            class="mb-auto ml-auto w-full max-w-max justify-self-end text-xs md:text-base lg:text-lg"
            is-small
            :class="{
              'leading-none': !open,
              'mx-auto': open,
            }"
            :trailing-icon="open ? 'chevron-up' : 'chevron-down'"
            @click.prevent="open = !open"
          >
            Filters
          </TextButton>
        </div>
        <div class="flex w-full flex-col gap-1.5 self-stretch" :class="{ hidden: !open }">
          <TgButton color="primary" is-small class="text-sm" @click.prevent="applyFilters">Apply Filters</TgButton>
          <OutlineButton color="primary" is-small class="text-sm" @click.prevent="$emit('reset')"
            >Reset Filters</OutlineButton
          >
        </div>
      </div>
    </FilterBox>
  </FormKit>
</template>
