import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { fetchHandler } from "@/bapi-client/fetch";
import type { HandlerResponse } from "@/bapi-client/types/handlerResponse";

export async function getReportingGroups(
  customerId: string,
  search_term?: string,
): HandlerResponse[typeof BAPI_COMMANDS.GET_REPORTING_GROUPS] {
  const path = `/reports/${customerId}/groups`;

  const response = await fetchHandler(BAPI_COMMANDS.GET_REPORTING_GROUPS, "POST", path, {
    body: {
      search_term,
    },
  });

  if (!response.success) {
    return response;
  }

  const { data } = response;
  const jsonResult = await data.json();

  return !jsonResult.success ? jsonResult : { success: true, data: jsonResult.data };
}
