import { RouteRecordRaw } from "vue-router";
import useFleetStore from "@/stores/fleets";
import useTrackerStore from "@/stores/trackers";
import { RUDDERSTACK_EVENTS } from "@/lib/rudderstack";
const VisibilityPage = () => import("@/pages/visibility/VisibilityPage.vue");
const AssetList = () => import("@/components/visibility/assets/AssetList.vue");
const AssetDetails = () => import("@/components/visibility/assets/asset_details/AssetDetails.vue");
const FleetCyclesPage = () => import("@/pages/visibility/FleetCyclesPage.vue");

const routes: RouteRecordRaw = {
  name: "visibilityPage",
  path: "/visibility",
  component: VisibilityPage,
  props: true,
  meta: {
    authenticated: true,
    flag: "hasVisibilityFeatures",
    breadcrumbs: [
      { label: "Visibility" },
      { label: "Assets", to: "assetsList", on: ["assetsList", "assetLookup", "assetDetails"] },
      { label: "Asset Details", to: "assetDetails", on: ["assetDetails"] },
      { label: "Bulk Search", to: "assetLookup", on: ["assetLookup"] },
      { label: "Fleet Cycles", to: "fleetPage", on: ["fleetPage"] },
    ],
  },
  children: [
    {
      name: "assetsList",
      path: ":companyId/assets",
      props: true,
      component: AssetList,
      meta: {
        title: "Telegraph - Visibility Dashboard",
      },
      beforeEnter: (to) => {
        if (to.query.widget) {
          const trackers = useTrackerStore();
          trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.DASHBOARD_WIDGET_VISIT_VISIBILITY_DASHBOARD, {
            widget: to.query.widget as string,
          });
        }
      },
    },
    {
      name: "assetDetails",
      path: ":companyId/waybills/:waybillId",
      props: true,
      components: {
        default: AssetDetails,
      },
      beforeEnter: async (to) => {
        // if (to.query.sort_column) {
        //   assetsStore.sortColumn = to.query.sort_column.toString();
        // }
        if (to.query.widget) {
          const trackers = useTrackerStore();
          trackers.logRudderstackEvent(RUDDERSTACK_EVENTS.DASHBOARD_WIDGET_VISIT_ASSET_DETAILS, {
            waybill_id: to.params.waybillId as string,
            widget: to.query.widget as string,
          });
        }
        // if (to.query.sort_direction) {
        //   assetsStore.sortDirection = to.query.sort_direction.toString() as "ASC" | "DESC";
        // }
      },
    },
    {
      name: "fleetPage",
      components: {
        default: FleetCyclesPage,
      },
      path: ":companyId/fleets",
      meta: {
        flag: "hasFleetCyclesModule",
        title: "Telegraph - Fleet Cycles",
      },
      async beforeEnter(to) {
        const store = useFleetStore();
        await store.getReportingGroups();
        if (to.query.group_id) {
          await store.selectReportingGroup(to.query.group_id as string);
        }

        if (to.query.equipment_id) {
          store.selectedEquipmentId = to.query.equipment_id as string;
        }

        if (to.query.detail_view === "events") {
          await store.getEquipmentEvents();
        }
      },
    },
  ],
};

export default routes;
