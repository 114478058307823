import { ProfilePicture } from "@/bapi-client/types/users";
import { toAlphanumeric } from "@/utils/text";

export interface APICustomerUser {
  user_id: string;
  first_name: string;
  last_name: string;
  is_admin: boolean;
  status: number;
  zip_code: string | null;
  phone_number_work: string | null;
  phone_number_mobile: string | null;
  email: string;
  user_token: boolean;
  companies: {
    company_id: string;
    company_name?: string;
    role_id: number;
    role_name: string;
    name?: string;
    id?: string;
  }[];
  profile_picture?: {
    normalized_url: string;
    thumbnail_url: string;
    raw_url: string;
    filename: string;
    mime_type: string;
  };
}

export interface CustomerUser {
  userId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  isAdmin: boolean;
  status: number;
  zipCode: string | null;
  phoneNumberWork: string | null;
  phoneNumberMobile: string | null;
  email: string;
  userToken: boolean;
  customers: {
    customerId: string;
    customerName: string;
    roleId: number;
    roleName: string;
  }[];
  searchTag: string;
  profilePicture?: ProfilePicture;
}

export interface UserCustomer {
  customerId: string;
  customerName: string;
  roleId: number;
  roleName: string;
}

export function makeCustomerUser(data: APICustomerUser): CustomerUser {
  const user: CustomerUser = {
    userId: data.user_id,
    firstName: data.first_name,
    lastName: data.last_name,
    get fullName() {
      return `${this.firstName} ${this.lastName}`;
    },
    isAdmin: data.is_admin,
    status: data.status,
    zipCode: data.zip_code,
    phoneNumberWork: data.phone_number_work,
    phoneNumberMobile: data.phone_number_mobile,
    email: data.email,
    userToken: data.user_token,
    customers: data.companies.map((customer) => ({
      customerId: customer.id ?? customer.company_id,
      customerName: customer.company_name ?? customer.name ?? "",
      roleId: customer.role_id,
      roleName: customer.role_name,
    })),
    get searchTag() {
      return toAlphanumeric(
        `${this.firstName}${this.lastName}${this.email}${this.customers.map((cust) => cust.customerName).join("")}`,
      );
    },
  };
  if (data.profile_picture) {
    user.profilePicture = data.profile_picture;
  }
  return user;
}
