import { HandlerResponse } from "../../types/handlerResponse";
import { BAPI_COMMANDS } from "../../types/commands";
import type { Result } from "../../types";
import { fetchHandler } from "@/bapi-client/fetch";
import { userFactory } from "@/bapi-client/factories/users";
import { HandlerParams } from "@/bapi-client/types/handlerParams";
import { uploadS3File } from "..";

export async function getMe(): HandlerResponse[typeof BAPI_COMMANDS.USER_GET_ME] {
  const response = await fetchHandler(BAPI_COMMANDS.USER_GET_ME, "GET", "/user/me");
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: userFactory(parsed.data),
  };
}

export async function loginUser(
  credentials: HandlerParams[typeof BAPI_COMMANDS.USER_LOGIN],
): HandlerResponse[typeof BAPI_COMMANDS.USER_LOGIN] {
  const response = await fetchHandler(BAPI_COMMANDS.USER_LOGIN, "POST", "/user/login", {
    body: credentials,
  });
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: userFactory(parsed.data),
  };
}

export async function logoutUser(): HandlerResponse[typeof BAPI_COMMANDS.USER_LOGOUT] {
  await fetchHandler(BAPI_COMMANDS.USER_LOGOUT, "DELETE", "/user/me");
  return { success: true, data: true };
}

export async function getProfilePictureUpload() {
  return await fetchHandler(BAPI_COMMANDS.USER_GET_PROFILE_PICTURE_UPLOAD_URL, "GET", "/user/profile_picture");
}

export async function uploadUserProfilePicture(
  ...[file, altText]: HandlerParams[typeof BAPI_COMMANDS.USER_UPLOAD_PROFILE_PICTURE]
): HandlerResponse[typeof BAPI_COMMANDS.USER_UPLOAD_PROFILE_PICTURE] {
  const signedResult = await getProfilePictureUpload();
  if (!signedResult.success) return signedResult;
  const parsedUpload = await signedResult.data.json();
  if (!parsedUpload.success) return parsedUpload;
  const { upload_url, attachment_id } = parsedUpload.data;

  const s3Result = await uploadS3File(file, upload_url);
  if (!s3Result.success) return s3Result;

  const uploadResult = await fetchHandler(BAPI_COMMANDS.USER_UPLOAD_PROFILE_PICTURE, "PUT", "/user/profile_picture", {
    body: {
      alt_text: altText,
      attachment_id,
      filename: file.name,
      filesize: file.size,
      mime_type: file.type,
    },
  });

  if (!uploadResult.success) return uploadResult;
  return { success: true, data: true };
}

export async function getPasswordResetEmail(email: string): Promise<Result<boolean>> {
  const response = await fetchHandler(BAPI_COMMANDS.USER_GET_PASSWORD_RESET_EMAIL, "POST", "/user/forgot", {
    body: { email },
  });
  if (!response.success) return response;
  return { success: true, data: true };
}

export async function resetUserPassword(password: string, token: string): Promise<Result<boolean>> {
  const response = await fetchHandler(BAPI_COMMANDS.USER_RESET_PASSWORD, "POST", "/user/reset", {
    body: {
      password,
      token,
    },
  });
  if (!response.success) return response;
  return { success: true, data: true };
}
