import { fetchHandler } from "@/bapi-client/fetch";
import type { Result } from "@/bapi-client/types";
import { AutocompleteUser, BAPIAutocompleteParams } from "@/bapi-client/types/autocomplete";
import { BAPI_COMMANDS } from "@/bapi-client/types/commands";

export async function autocompleteUsers(
  customerId: string,
  params: { searchTerm: string; page?: number; pageSize?: number },
): Promise<Result<AutocompleteUser[]>> {
  const path = `/autocomplete/${customerId}/users`;
  const body: BAPIAutocompleteParams = {
    search_term: params.searchTerm,
    page: params.page,
    page_limit: params.pageSize,
  };

  const fetchResult = await fetchHandler(BAPI_COMMANDS.AUTOCOMPLETE_USERS, "POST", path, {
    body,
  });

  if (!fetchResult.success) {
    return fetchResult;
  }

  const jsonResult = await fetchResult.data.json();
  if (!jsonResult.success) return jsonResult;

  return {
    success: true,
    data: jsonResult.data.data,
  };
}
